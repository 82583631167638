/********** Theme: friday **********/
/* Font styles */
.flipdown__theme-friday
  font-family: sans-serif
  font-weight: bold
  margin-left: auto
  margin-right: auto

  .rotor-group:after,
  .rotor-group:before
    box-shadow: 1px 1px 0 rgba(0, 0, 0, .8)
    background-color: #fff !important

  .rotor-group-heading
    position: absolute

    &:before
      color: #fff !important
      text-shadow: 1px 1px 0 rgba(0, 0, 0, .8)
  .rotor-group:nth-child(4) .rotor-group-heading
    width: 100%

  .rotor
    margin: 0

  .rotor-group .rotor
    border-radius: 4px 0 0 4px !important
    .rotor-bottom
      border-radius: 0 0 0 4px !important
    ~ .rotor
      border-radius: 0 4px 4px 0 !important
      .rotor-bottom
        border-radius: 0 0 4px 0 !important

  .rotor:after
    border-radius: 0 !important

/* Rotor group headings */
.flipdown__theme-friday .rotor-group-heading:before
  color: #000000

/* Delimeters */
.flipdown__theme-friday .rotor-group:nth-child(n+2):nth-child(-n+3):before,
.flipdown__theme-friday .rotor-group:nth-child(n+2):nth-child(-n+3):after
  background-color: #151515

/* Rotor tops */
.flipdown__theme-friday .rotor,
.flipdown__theme-friday .rotor-top,
.flipdown__theme-friday .rotor-leaf-front
  color: #FFFFFF
  background-color: #151515

/* Rotor bottoms */
.flipdown__theme-friday .rotor-bottom,
.flipdown__theme-friday .rotor-leaf-rear
  color: #EFEFEF
  background-color: #202020

/* Hinge */
.flipdown__theme-friday .rotor:after
  border-top: solid 1px #151515
@media (min-width: 551px)
  .flipdown__theme-friday
    width: 490px
  .rotor-group-heading
    bottom: -30px
    width: calc(100% - 30px)

  .rotor-group:after,
  .rotor-group:before
    left: 110px !important

@media (max-width: 550px)
  .flipdown__theme-friday
    width: 300px
  .rotor-group-heading
    bottom: -20px
    width: calc(100% - 20px)

  .rotor-group:after,
  .rotor-group:before
    left: 66px !important