:root
   --accent: #1e8888

body
  font-family: 'Helvetica Neue', 'Helvetica', 'Roboto', 'Segoe UI', 'Oxygen', sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  display: block
  grid-template-columns: none

  > *
    grid-column: unset


.app-container
  position: relative
  height: 100vh
  width: 100vw
  display: flex
  align-items: center
  justify-content: center
  text-align: center
  flex-flow: column

  .background-image-container
    position: absolute
    width: 100%
    height: 100%
    background-repeat: no-repeat
    background-position: center center
    background-attachment: fixed
    background-size: cover
    background-color: #f7f7f7
    //background-image: url('https://unsplash.it/1200/900?random')

    img
      position: absolute
      inset: 0
      object-fit: cover
      width: 100%
      height: 100%

  .content
    position: relative

    > h1,
    > p
      text-shadow: 1px 1px 0 rgba(0, 0, 0, .8)
      color: #fff

    > p
      margin: 0
      padding: 0
      font-size: 1.5rem

    > .button
      padding: 1rem
      font-weight: 700
      font-size: 1.25rem
      text-transform: uppercase

    h1
      margin-top: 24px
      margin-bottom: 24px

  .mt-half
    margin-top: 12px

@import ~@snapboard/flipdown/dist/flipdown.min.css
@import 'flipdown'
@import 'loader'
