.background-backdrop
  position: absolute
  width: 100%
  height: 100%
  background-color: #000
  opacity: .4
  will-change: transform
  transition: opacity ease-in-out .25s .25s

  &.is-loading
    opacity: 1
    &::after
      position: absolute
      bottom: 1em
      right: 1em
      display: block
      border-radius: 100%
      content: ''
      z-index: 9999
      width: 0
      height: 0
      box-sizing: border-box
      border: 0 solid #fff
      animation: loader-figure 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1)
      animation-delay: .25s

  @keyframes loader-figure
    0%
      height: 0
      width: 0
      opacity: 0
      background-color: #fff
    15%
      opacity: .5
      height: 1em
      width: 1em
    29%
      opacity: 1
      background-color: #fff
    30%
      height: 2em
      width: 2em
      background-color: transparent
      border-width: 1em
      opacity: 1
    100%
      height: 2em
      width: 2em
      border-width: 0
      opacity: 0
      background-color: transparent
